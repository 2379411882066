import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { $count, $ok, $value } from "foundation-ts/commons";
import { Nullable, UUID } from "foundation-ts/types";
import { SessionDto } from "g1-commons/lib/doxecureClientTypes";
import { TokenSessionStructure } from "g1-commons/lib/doxecureTypes";

import { $g1Color, $g1IconURL } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { TemporaryChildProps } from "../../components/layouts/TemporaryAuthenticatedLayout";

import { config } from "../../config/config";
import { useDevice } from "../../hooks/useDevice";

import { DocumentsViewer } from "../generics/DocumentsViewer";
import { G1Button } from "../../components/G1Button";

export interface ExternalSignSharedData extends TokenSessionStructure {
    session?: Nullable<SessionDto> ;
    token?: Nullable<string> ;
}

export const ExternalSignView = ({ sharedData } : TemporaryChildProps) => {
    const { isMOBILE } = useDevice() ;
    const [selectedSession, setSelectedSession] = useState<Nullable<SessionDto>>(null) ;
    const [profileId, setProfileId] = useState<Nullable<UUID>>(null) ;
    const [token, setToken] = useState<Nullable<string>>(null) ;
    const [senderName, setSenderName] = useState("") ;
    const [message, setMessage] = useState("") ;
    const [displayFlex1, setDisplayFlex1] = useState(true) ;
    const [displayFlex2, setDisplayFlex2] = useState(!isMOBILE) ;

    const resetAndBack = () => {
        setDisplayFlex1(true) ;
        setDisplayFlex2(false) ;
    }

    const onClickStart = () => {
        setDisplayFlex1(false) ;
        setDisplayFlex2(true) ;
    }

    useEffect(() => {
        if ($ok(sharedData)) {
            const session = (sharedData as ExternalSignSharedData).session ;
            setSelectedSession(session) ;
            setProfileId((sharedData as ExternalSignSharedData).profileId) ;
            setToken((sharedData as ExternalSignSharedData).token) ;

            const sender = $ok(session?.expeditor) ? `${session!.expeditor!.firstName} ${session!.expeditor!.lastName.toUpperCase()}` : "Inconnu" ;
            setSenderName(sender) ;
            const msg =  `${sender} vous a transmis ${$count(session?.documents)} document${$count(session?.documents) > 1 ? "s" : ""} à signer avant le ${$value(session?.validity?.end?.toLocaleString("%d/%m/%Y"), '')} avec votre clé de signature.`;
            setMessage(msg);
        }
    }, [sharedData]) ;

    return (
        <>
            <Flex flexDirection="column" borderRightWidth={{ lg: "1px", sm: "none" }} mt="3" pl="10" pr="10" width={isMOBILE ? "100%" : "10%"} h="full" display={{ base: displayFlex1 ? "block" : "none", md: "block" }}>
                <Box mb="6" display="flex" justifyContent="space-between" alignItems="flex-end" color={$g1Color("sidebar.logo.write")} bg={$g1Color("sidebar.logo.bg")}>
                    <Stack direction="row" alignItems="center" spacing="5">
                        <Image src={$g1IconURL("sidebar.logo.iconurl")} alt="Application logo" boxSize="48px" />
                        <Text>{config.app.title}</Text>
                    </Stack>
                </Box>
                <Text>{message}</Text>
                <Text mt="5" mb="5">{`Préparez votre clé et ${isMOBILE ? "cliquez sur le boutonn ci-dessous pour commencer le processus." : "scrollez jusqu'au bout les documents ci-contre afin de pouvoir les signer."}`}</Text>
                {isMOBILE && <G1Button component="form" variant="important" title={"Commencer"} width="100%" onClick={onClickStart} />}
            </Flex>
            <Flex flexDirection="column" overflow="auto" mt="3" pl="10" h="full" display={{ base: displayFlex2 ? "block" : "none", md: "block" }}>
                <DocumentsViewer
                    profileId={profileId}
                    sessionId={selectedSession?.apid}
                    acceptsSignature={true}
                    validityDate={$value(selectedSession?.validity?.end?.toLocaleString("%d/%m/%Y"), '')}
                    immutableDocuments={true}
                    senderName={senderName}
                    signatureMode={selectedSession?.signatures?.first()?.signatureMode}
                    token={token}
                    resetAndBack={resetAndBack}
                />
            </Flex>
        </>
    ) ;
} ;