import React from "react";

import { $ok } from "foundation-ts/commons";

import { TemporaryAuthenticatedLayout } from "../components/layouts/TemporaryAuthenticatedLayout";
import { ExternalSignView } from "../containers/toBeSignedDocuments/ExternalSignView";

import { getOneSessionForUser, getTokenSessionStruct } from "../services/session.service";

const ExternalSignPage = () => {
    return (
        <TemporaryAuthenticatedLayout getData={async (token: string) => { 
            const tokenSessionStructure = await getTokenSessionStruct(token) ;
            if (!$ok(tokenSessionStructure)) { return null ; }

            try {
                const session = await getOneSessionForUser(tokenSessionStructure?.sessionId, token) ;
                return { sessionId: tokenSessionStructure!.sessionId, profileId: tokenSessionStructure!.profileId, session: session, token: token } ;
            } catch {
                return null ;
            }
        }}>
            <ExternalSignView />
        </TemporaryAuthenticatedLayout>
    ) ;
}

export default ExternalSignPage ;